var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('table-data',{attrs:{"title":_vm.title,"table-headers":_vm.tableHeaders,"default-table-headers":[],"form-scheme":_vm.formScheme,"resource":_vm.resource,"expanded":_vm.expanded,"show-expand":_vm.$can('domains.providers.view') ? _vm.showExpand : false,"single-expand":_vm.singleExpand,"item-expanded":_vm.detDomainsList,"item-class-fn":_vm.itemClassFn},on:{"changeSelected":_vm.handleSelected,"currentItems":_vm.handleCurrentItems,"inputChange":_vm.handleChange,"dialogChange":_vm.handleDialog},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"ma-1"},[_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showTeam),expression:"showTeam"}],staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.team)+" "+_vm._s(item.process || '')+" ")])])]}},{key:"item.login",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pt-2 pb-2"},[(item.login)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.login)+" ")]),(!!item.email_address && item.email_address !== item.login)?_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.email_address)+" ")]):_vm._e()]):(!item.login && item.email_address)?_c('div',{staticClass:"mb-1"},[_c('div',[_vm._v(" "+_vm._s(item.email_address)+" ")])]):_vm._e()])]}},{key:"item.proxy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_vm._v(" "+_vm._s(item.proxy.name)+" ")]),_c('div',{staticClass:"grey--text small"},[_vm._v(" "+_vm._s(item.proxy.id ? ((item.proxy.type) + "://" + (item.proxy.host) + ":" + (item.proxy.port)) : '—')+" ")])]}},{key:"action-buttons",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('domains.providers.table.btn.credentials'),"icon":"key-chain-variant","can":"domains.providers.auth","fn":_vm.getCredentials}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('domains.providers.table.btn.reg'),"icon":"plus","can":"domains.providers.create","fn":_vm.domainsRegister}}),_c('table-btn',{attrs:{"item":item,"tooltip-text":_vm.$t('domains.providers.table.btn.import'),"loading":item.loading,"icon":"database-import-outline","can":"domains.providers.import","fn":_vm.importDomains}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item}})],1)]}}],null,true)}),(this.$can('domains.providers.create'))?_c('domains-register',{attrs:{"dialog":_vm.domainsRegisterDialog,"edited-item":_vm.domainsRegisterEditedItem},on:{"close":_vm.domainsRegisterDialogClose}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }