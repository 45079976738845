var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-3"},[_c('v-text-field',{ref:"search",staticClass:"mb-5",attrs:{"hide-details":"","single-line":"","clearable":"","append-icon":"mdi-magnify","placeholder":"Enter keywords..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-1 domains-table",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"show-expand":_vm.showExpand,"single-expand":_vm.singleExpand,"hide-default-footer":false,"items-per-page":10,"item-key":"name","item-class":_vm.itemClass},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var expand = ref.expand;
var isExpanded = ref.isExpanded;
return [_c('v-btn',{attrs:{"icon":"","disabled":item.loading || _vm.$helper.isCloudflareNS(item.ns_records) || !item.ns_records || ['active'].indexOf(item.status) < 0},on:{"click":function($event){return expand(!isExpanded)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-chevron-down ")])],1)]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://' + item.name,"target":"_blank"},on:{"dblclick":function($event){return _vm.$helper.openWindow(item.name)},"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('table-btn',{attrs:{"item":item,"loading":item.loading,"tooltip-text":_vm.$t('domains.providers.table.btn.sync'),"icon":"autorenew","can":"domains.providers.sync","fn":_vm.domainSync}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading || _vm.$helper.isCloudflareNS(item.ns_records) || !item.ns_records || ['active'].indexOf(item.status) < 0,"tooltip-text":_vm.$t('domains.providers.table.btn.addDns'),"icon":"plus","can":"domains.providers.create","fn":_vm.dnsAdd}}),_c('table-btn',{attrs:{"item":item,"disabled":item.loading || ['active'].indexOf(item.status) < 0,"tooltip-text":_vm.$t('domains.providers.table.btn.nssChange'),"icon":"dns-outline","can":"domains.providers.update","fn":_vm.nssUpdate}})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"ma-2",attrs:{"small":""}},[_vm._v(" "+_vm._s(item.status || '—')+" ")])]}},{key:"item.ns_records",fn:function(ref){
var item = ref.item;
return [_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},_vm._l((item.ns_records),function(ns,key){return _c('v-chip',{key:key,staticClass:"ma-2",attrs:{"small":"","close":"","close-icon":"mdi-content-copy"},on:{"click:close":function($event){return _vm.$copyToClipboard(ns)}}},[_vm._v(" "+_vm._s(ns)+" ")])}),1)]}},{key:"item.dns_records",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helper.has(item, 'dns_records') ? Object.keys(item.dns_records || {}).length : '—')+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c(_vm.detailsComponent,{tag:"component",attrs:{"params":item}})],1)]}}],null,true)}),_c('dialogs-nameservers',{attrs:{"dialog":_vm.nssDialog,"edited-item":_vm.nssEditedItem},on:{"close":_vm.nssDialogClose}}),_c('dialogs-domains-dns',{attrs:{"dialog":_vm.dnsDialog,"edited-item":_vm.dnsEditedItem,"action":'create'},on:{"close":_vm.closeDnsDialog}}),_c('dialog-confirm',{attrs:{"dialog":_vm.domainDialogDelete,"title":_vm.$t('domains.providers.domains.dialog.removeConfirmTitle'),"confirm-loading":_vm.domainRemoveLoading},on:{"close":_vm.domainDialogDeleteClose,"confirm":_vm.domainRemoveConfirm}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }