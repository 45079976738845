<template>
  <div class="mt-3 mb-3">
    <v-text-field
      ref="search"
      v-model="search"
      hide-details
      single-line
      clearable
      append-icon="mdi-magnify"
      class="mb-5"
      placeholder="Enter keywords..."
    />
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="items"
      :search="search"
      :show-expand="showExpand"
      :single-expand="singleExpand"
      :hide-default-footer="false"
      :items-per-page="10"
      item-key="name"
      :item-class="itemClass"
      class="elevation-1 domains-table"
    >
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="item.canExpand && !isExpanded"
          @click="expand(true)"
        >
          Expand
        </v-btn>
        <v-btn
          v-if="item.canExpand && isExpanded"
          @click="expand(false)"
        >
          close
        </v-btn>
      </template>

      <template
        #[`item.name`]="{ item }"
      >
        <a
          :href="'https://' + item.name"
          target="_blank"
          @dblclick="$helper.openWindow(item.name)"
          @click.prevent=""
        >{{ item.name }}</a>
      </template>
      <template
        #[`item.actions`]="{ item }"
      >
        <table-btn
          :item="item"
          :loading="item.loading"
          :tooltip-text="$t('domains.providers.table.btn.sync')"
          :icon="`autorenew`"
          :can="`domains.providers.sync`"
          :fn="domainSync"
        />
        <table-btn
          :item="item"
          :disabled="item.loading || $helper.isCloudflareNS(item.ns_records) || !item.ns_records || ['active'].indexOf(item.status) < 0"
          :tooltip-text="$t('domains.providers.table.btn.addDns')"
          :icon="`plus`"
          :can="`domains.providers.create`"
          :fn="dnsAdd"
        />
        <table-btn
          :item="item"
          :disabled="item.loading || ['active'].indexOf(item.status) < 0"
          :tooltip-text="$t('domains.providers.table.btn.nssChange')"
          :icon="`dns-outline`"
          :can="`domains.providers.update`"
          :fn="nssUpdate"
        />
      </template>

      <template #[`item.status`]="{ item }">
        <v-chip
          small
          class="ma-2"
        >
          {{ item.status || '—' }}
        </v-chip>
      </template>
      <template #[`item.ns_records`]="{ item }">
        <v-chip-group
          active-class="primary--text"
          column
        >
          <v-chip
            v-for="(ns, key) in item.ns_records"
            :key="key"
            small
            class="ma-2"
            close
            close-icon="mdi-content-copy"
            @click:close="$copyToClipboard(ns)"
          >
            {{ ns }}
          </v-chip>
        </v-chip-group>
      </template>
      <template
        #[`item.dns_records`]="{ item }"
      >
        {{ $helper.has(item, 'dns_records') ? Object.keys(item.dns_records || {}).length : '&mdash;' }}
      </template>
      <template v-slot:item.data-table-expand="{ item, expand, isExpanded }">
        <v-btn
          icon
          :disabled="item.loading || $helper.isCloudflareNS(item.ns_records) || !item.ns_records || ['active'].indexOf(item.status) < 0"
          @click="expand(!isExpanded)"
        >
          <v-icon small>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <component
            :is="detailsComponent"
            :params="item"
          />
        </td>
      </template>
    </v-data-table>
    <dialogs-nameservers
      :dialog="nssDialog"
      :edited-item="nssEditedItem"
      @close="nssDialogClose"
    />
    <dialogs-domains-dns
      :dialog="dnsDialog"
      :edited-item="dnsEditedItem"
      :action="'create'"
      @close="closeDnsDialog"
    />
    <dialog-confirm
      :dialog="domainDialogDelete"
      :title="$t('domains.providers.domains.dialog.removeConfirmTitle')"
      :confirm-loading="domainRemoveLoading"
      @close="domainDialogDeleteClose"
      @confirm="domainRemoveConfirm"
    />
  </div>
</template>

<script>
import ProviderDomainDetails from './ProviderDomainDetails'

import DialogsNameservers from '../dialogs/Nameservers'
import DialogsDomainsDns from '../dialogs/DomainsDns'

export default {
  name: 'ProviderDetails',
  components: {
    DialogsNameservers,
    DialogsDomainsDns
  },
  props: {
    params: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    loading: false,
    showExpand: true,
    singleExpand: true,
    detailsComponent: ProviderDomainDetails,
    headers: [],
    items: [],
    search: '',
    // DOMAIN
    domainsLoading: false,
    domainEditedItem: {},
    domainDialogDelete: false,
    domainRemoveLoading: false,
    // NSS
    nssDialog: false,
    nssEditedItem: {},
    // DNS RECORD
    dnsDialog: false,
    dnsEditedItem: {},
    // Locked entites
    lockedEntities: []
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    }
  },
  watch: {
    params (val) {
      this.items = val.domains
    }
  },
  async mounted () {
    this.$nextTick(() => this.$refs.search.focus())
    this.items = this.params.domains
    this.headers = [
      {
        text: this.$t('domains.providers.domains.table.name'),
        align: 'start',
        value: 'name'
      },
      {
        text: this.$t('domains.providers.domains.table.nss'),
        align: 'start',
        value: 'ns_records'
      },
      {
        text: this.$t('domains.providers.domains.table.records'),
        value: 'dns_records',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('domains.providers.domains.table.status'),
        value: 'status',
        width: '100px',
        align: 'center',
        sortable: false
      },
      {
        text: this.$t('domains.providers.domains.table.actions'),
        value: 'actions',
        width: '180px',
        align: 'center',
        sortable: false
      }
      /* ,{ text: '', value: 'data-table-expand' } */
    ]
  },
  methods: {
    /* ------------------------------------------ */
    // DOMAINS
    /* ------------------------------------------ */
    async domainSync (item) {
      this.$set(item, 'loading', true)
      try {
        await this.$store.dispatch('domainsProviders/domainSync', {
          id: this.params.id,
          domainId: item._id,
          name: item.name
        })
      } catch (e) {
        console.log(e)
      } finally {
        // this.$store.commit('__domains.providers/mergeItemData', { ...item, ...{ loading: false } })
      }
    },
    domainRemove (item) {
      this.domainDialogDelete = true
      this.domainEditedItem = Object.assign({}, {
        id: this.params.id,
        domainId: item._id,
        name: item.name
      })
    },
    async domainRemoveConfirm () {
      this.domainRemoveLoading = true
      try {
        const response = await this.$store.dispatch('domainsProviders/domainRemove', this.domainEditedItem)
        if (response.success === true) {
          await this.$toast.success(this.$t('domains.providers.remove.success'))
        } else {
          await this.$toast.error(response.errors)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.domainRemoveLoading = false
        this.domainDialogDeleteClose()
      }
    },
    domainDialogDeleteClose () {
      this.domainDialogDelete = false
      this.domainEditedItem = {}
    },
    domainRowClass (item) {
      let classes
      switch (item.cloudflare_status) {
        case 'active':
          classes += ' blue lighten-5 '
          break
        case 'pending':
          classes += ' yellow lighten-3 '
          break
        default:
          classes += ' red lighten-5 '
          break
      }
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        item.loading = true
      } else {
        item.loading = false
      }
      return classes
    },
    /* ------------------------------------------ */
    // NSS
    /* ------------------------------------------ */
    nssUpdate(item) {
      this.nssEditedItem = Object.assign({}, {
        id: this.params.id,
        domain: item.name,
        ns_records: item.ns_records
      })
      this.nssDialog = true
    },
    nssDialogClose() {
      this.nssDialog = false
      this.nssEditedItem = Object.assign({}, {
        id: '',
        domain: '',
        ns_records: []
      })
    },
    /* ------------------------------------------ */
    // DNS
    /* ------------------------------------------ */
    dnsAdd (item) {
      this.dnsEditedItem = Object.assign({}, {
        id: this.params.id,
        domain: item.name
      })
      this.dnsDialog = true
    },
    closeDnsDialog () {
      this.dnsDialog = false
      this.dnsEditedItem = {
        id: '',
        domain: ''
      }
    },
    itemClass(item) {
      let classes
      if (this.$helper.isCloudflareNS(item.ns_records)) {
        classes += ' orange lighten-5 '
      }
      if (item.status === 'unpaid') {
        classes += ' grey lighten-3 '
        this.$set(item, 'canExpand', true)
      }
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
        item.loading = true
      } else {
        item.loading = false
      }
      return classes
    }
  }
}
</script>

<style lang="scss">
.domains-table {
  .v-data-table-header {
    background: #F5F5F5;
  }
}
</style>
