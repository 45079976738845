var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-3 mb-3"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"show-expand":_vm.showExpand,"hide-default-footer":true,"items-per-page":-1},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":'https://' + item.name,"target":"_blank"},on:{"dblclick":function($event){return _vm.$helper.openWindow(item.name)},"click":function($event){$event.preventDefault();}}},[_vm._v(_vm._s(item.name))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.dnsUpdate(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return _vm.dnsRemove(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.proxied",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{attrs:{"input-value":item.proxied,"hide-details":"auto","disabled":""}})]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(Object.values(item.dns_records).length)+" ")]}},{key:"item.content",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-no-wrap"},[_c('v-chip-group',{attrs:{"active-class":"primary--text","column":""}},[_c('v-chip',{staticClass:"ma-2 chip-with-copy",attrs:{"small":"","close":"","close-icon":"mdi-content-copy"},on:{"click:close":function($event){return _vm.$copyToClipboard(item.content)}}},[_vm._v(" "+_vm._s(item.content)+" ")])],1)],1)]}}],null,true)}),_c('dialogs-domains-dns',{attrs:{"dialog":_vm.dnsDialog,"edited-item":_vm.dnsEditedItem,"action":'update'},on:{"close":_vm.closeDnsDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }