<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <table-data
          :title="title"
          :table-headers="tableHeaders"
          :default-table-headers="[]"
          :form-scheme="formScheme"
          :resource="resource"
          :expanded="expanded"
          :show-expand="$can('domains.providers.view') ? showExpand : false"
          :single-expand="singleExpand"
          :item-expanded="detDomainsList"
          :item-class-fn="itemClassFn"
          @changeSelected="handleSelected"
          @currentItems="handleCurrentItems"
          @inputChange="handleChange"
          @dialogChange="handleDialog"
        >
          <template #[`item.name`]="{ item }">
            <div class="ma-1">
              <div class="text-no-wrap">
                {{ item.name }}
              </div>
              <div
                v-show="showTeam"
                class="grey--text small"
              >
                {{ item.team }} {{ item.process || '' }}
              </div>
              <!-- div
                class="grey--text small"
              >
                {{ item.login }} {{ !!item.email && item.email !== item.login ? `/ ${item.email}` : '' }}
              </div-->
            </div>
          </template>
          <template #[`item.login`]="{ item }">
            <div class="pt-2 pb-2">
              <div
                v-if="item.login"
                class="mb-1"
              >
                <div>
                  {{ item.login }}
                </div>
                <div
                  v-if="!!item.email_address && item.email_address !== item.login"
                  class="grey--text small"
                >
                  {{ item.email_address }}
                </div>
              </div>
              <div
                v-else-if="!item.login && item.email_address"
                class="mb-1"
              >
                <div>
                  {{ item.email_address }}
                </div>
              </div>
            </div>
          </template>
          <template #[`item.proxy`]="{ item }">
            <div class="text-no-wrap">
              {{ item.proxy.name }}
            </div>
            <div class="grey--text small">
              {{ item.proxy.id ? `${item.proxy.type}://${item.proxy.host}:${item.proxy.port}` : '&mdash;' }}
            </div>
          </template>

          <template #action-buttons="{ item }">
            <table-btn
              :item="item"
              :tooltip-text="$t('domains.providers.table.btn.credentials')"
              :icon="`key-chain-variant`"
              :can="`domains.providers.auth`"
              :fn="getCredentials"
            />

            <table-btn
              :item="item"
              :tooltip-text="$t('domains.providers.table.btn.reg')"
              :icon="`plus`"
              :can="`domains.providers.create`"
              :fn="domainsRegister"
            />

            <table-btn
              :item="item"
              :tooltip-text="$t('domains.providers.table.btn.import')"
              :loading="item.loading"
              :icon="`database-import-outline`"
              :can="`domains.providers.import`"
              :fn="importDomains"
            />
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <component
                :is="detailsComponent"
                :params="item"
              />
            </td>
          </template>
        </table-data>
        <domains-register
          v-if="this.$can('domains.providers.create')"
          :dialog="domainsRegisterDialog"
          :edited-item="domainsRegisterEditedItem"
          @close="domainsRegisterDialogClose"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue'
import ProvidersDetails from '../details/ProvidersDetails'
import DomainsRegister from '../dialogs/DomainsRegister'

export default {
  name: 'Providers',
  metaInfo () {
    return {
      title: this.$store.getters['app/appTitle'],
      titleTemplate: `${this.$t('domains.providers.meta.title')} — %s`
    }
  },
  components: {
    DomainsRegister
  },
  data: () => ({
    title: '',
    tableHeaders: [],
    formScheme: {},
    resource: 'domains.providers',
    currentItems: [],
    editedItem: {},
    showTeam: false,
    expanded: [],
    showExpand: true,
    singleExpand: true,
    detailsComponent: ProvidersDetails,
    // DOMAINS REGISTER
    domainsRegisterDialog: false,
    domainsRegisterEditedItem: {}
  }),
  computed: {
    getLockedEntities() {
      return this.$store.getters['lockedEntities/getLockedEntities']
    },
    getFoundEmails() {
      return this.$store.getters['emails/emails']
    }
  },
  watch: {
    async 'editedItem.provider' (val) {
      Vue.set(this.formScheme.api_key, 'disabled', false)
      let url
      switch (val) {
        case 'namecheap':
          url = 'https://namecheap.com'
          break
        case 'godaddy':
          url = 'https://godaddy.com'
          break
        case 'namesilo':
          url = 'https://namesilo.com'
          Vue.set(this.formScheme.api_key, 'disabled', true)
          break
        case 'ukraine':
          url = 'https://ukraine.com.ua'
          break
      }
      Vue.set(this.editedItem, 'url', url)
    }
  },
  async mounted () {
    this.title = this.$t('domains.providers.title')

    let actionsColWight = 20
    actionsColWight += this.$can('domains.providers.auth') ? 20 : 0
    actionsColWight += this.$can('domains.providers.sync') ? 20 : 0
    actionsColWight += this.$can('domains.providers.update') ? 20 : 0
    actionsColWight += this.$can('domains.providers.remove') ? 20 : 0
    actionsColWight += this.$can('domains.providers.create') ? 20 : 0

    this.tableHeaders = [
      {
        text: this.$t('domains.providers.table.name'),
        align: 'start',
        value: 'name'
      },
      { text: this.$t('domains.providers.table.login'), value: 'login' },
      { text: this.$t('domains.providers.table.proxy'), value: 'proxy', width: '150px', align: 'center' },
      // { text: this.$t('domains.providers.table.team'), value: 'team', width: '150px', align: 'center' },
      { text: this.$t('domains.providers.table.provider'), value: 'provider', width: '100px', align: 'center' },
      { text: this.$t('domains.providers.table.num'), value: 'domainsNum', width: '100px', align: 'center' },
      {
        text: this.$t('tableData.creationDate'),
        align: 'center',
        value: 'createdAt',
        width: '170px'
      },
      {
        text: this.$t('tableData.actions'),
        align: 'center',
        value: 'actions',
        sortable: false,
        width: `${actionsColWight}px`
      }
    ]
    this.formScheme = {
      name: {
        type: 'text',
        label: this.$t('domains.providers.tableForm.name'),
        createRules: 'req',
        updateRules: []
      },
      provider: {
        type: 'select',
        label: this.$t('domains.providers.tableForm.provider'),
        items: [
          // { text: 'REG.RU', value: 'regru' },
          { text: 'NAMECHEAP', value: 'namecheap' },
          { text: 'GODADDY', value: 'godaddy' },
          { text: 'NAMESILO', value: 'namesilo' },
          { text: 'UKRAINE', value: 'ukraine' }
          // { text: 'Root', value: 'root' }
        ],
        createRules: 'req',
        updateRules: []
      },
      url: {
        type: 'text',
        label: this.$t('domains.providers.tableForm.url'),
        // createRules: 'req',
        createRules: [],
        updateRules: []
      },
      // email: {
      //   type: 'text',
      //   label: this.$t('domains.providers.tableForm.email'),
      //   updateRules: []
      // },
      email_id: {
        type: 'autocomplete',
        label: this.$t('hosting.providers.tableForm.email'),
        items: this.getFoundEmails,
        cacheItems: false,
        // changeFn: async (i, e, v) => {
        //   console.log('i', i)
        //   console.log('e', e)
        //   console.log('v', v)
        // },
        updateSearch: async (i, q, v) => {
          await this.$store.dispatch('emails/getAllEmails', q)
          return false
        },
        createRules: 'req',
        updateRules: 'req'
      },
      login: {
        type: 'text',
        label: this.$t('domains.providers.tableForm.login'),
        createRules: 'req',
        updateRules: []
      },
      password: {
        type: 'password',
        label: this.$t('domains.providers.tableForm.password'),
        ref: 'password',
        data: {
          show: false
        },
        createRules: 'req',
        updateRules: []
      },
      api_key: {
        type: 'text',
        label: this.$t('domains.providers.tableForm.apiKey'),
        createRules: [],
        updateRules: []
      },
      api_secret: {
        type: 'text',
        label: this.$t('domains.providers.tableForm.apiSecret'),
        createRules: [],
        updateRules: []
      },
      proxy_id: {
        type: 'autocomplete',
        label: this.$t('domains.providers.tableForm.proxy'),
        items: this.$can('proxies.list') ? await this.$store.dispatch('proxies/getProxiesList') : [],
        createRules: [],
        updateRules: [],
        md: 6
      },
      sandbox: {
        type: 'switch',
        label: this.$t('domains.providers.tableForm.sandbox'),
        createRules: [],
        updateRules: []
      }
    }
    if (this.$currentUser.role === process.env.VUE_APP_MAIN_ROLE || this.$currentUser.global === true) {
      this.showTeam = true
      this.tableHeaders = this.$helper.addToArray(this.tableHeaders, { text: this.$t('domains.providers.table.team'), value: 'team' }, 1)
      this.formScheme = this.$helper.addToObject(this.formScheme, 'affiliate_team_id', {
        type: 'autocomplete',
        label: this.$t('domains.providers.tableForm.teams'),
        items: await this.$store.dispatch('affiliatesTeams/all'),
        createRules: [], // v => !!v || this.$t('domains.tableForm.validation.fieldIsRequired')
        updateRules: [],
        md: 6
      }, 'proxy_id')
    }
  },
  methods: {
    async detDomainsList({ item, value }) {},
    async getCredentials ({ id }) {
      if (!this.$can('domains.providers.auth')) {
        await this.$toast.error(this.$t('permissions.noHavePermissions'))
        return
      }
      const result = await this.$store.dispatch('domainsProviders/getDomainsProviderCredentials', { id })
      if (result) {
        await this.$store.dispatch('dialog/dialog', {
          show: true,
          title: this.$t('dialog.credentials'),
          contentType: 'code',
          content: JSON.stringify(result, null, 2),
          maxWidth: 500
        })
      }
    },
    async importDomains (item) {
      try {
        await this.$store.dispatch('domainsProviders/importDomains', { id: item.id })
      } catch (e) {
        await this.$toast.error(e.response.data.message)
      }
    },
    domainsRegister (item) {
      this.domainsRegisterDialog = true
      this.domainsRegisterEditedItem = Object.assign({}, { id: item.id })
    },
    domainsRegisterDialogClose() {
      this.domainsRegisterDialog = false
      this.domainsRegisterEditedItem = {}
    },
    handleSelected (selected) {
      this.selected = selected
    },
    handleCurrentItems(items) {
      if (items.length > 0) {
        this.currentItems = items
      }
    },
    itemClassFn(item) {
      let classes
      if (this.getLockedEntities.indexOf(item._id) > -1) {
        classes += ' blue-grey lighten-5 loading-background '
      }
      return classes
    },
    handleChange (changes) {
      this.editedItem = changes
    },
    async handleDialog (event) {
      if (event.dialog === true) {
        this.editedItem = event.item

        await this.$store.dispatch('emails/getAllEmails', event.item.email_address)
      }
    }
  }
}
</script>

<style lang="scss"></style>
